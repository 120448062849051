// Utility function to format numbers with commas
export const formatNumberWithCommas = value => {
  // First, normalize the value to remove all non-numeric characters except digits
  let numericValue = value.replace(/[^0-9]/g, "")

  // Remove leading zeros by converting to a number, then back to a string
  numericValue = parseInt(numericValue, 10).toString()

  // Return early if parsing results in a non-numeric value (e.g., empty string or NaN)
  if (isNaN(numericValue) || numericValue === "NaN") {
    return ""
  }

  // Apply comma formatting
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
