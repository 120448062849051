import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import NewContactInfo from "../components/Contact/NewContactInfo"
import NewContactForm from "../components/Contact/NewContactForm"
import SEO from "../components/App/SEO"


const ApplyNow = () => {
  return (
    <Layout>
      <SEO
        title="Apply Now | Great SMSF Rates Start Here!"
        description="Send us a message and we'll get back to you shortly."
      />
      <Navbar />
      <PageBanner
        pageTitle="SMSF Enquiry"
        pageSubTitle="Send us a message and we'll get back to you shortly."
      />
      <div className="container frm-contact pb-100">
        <div className="row">
          <div className="col-lg-8 col-12 pr-5">
            <NewContactForm />
          </div>
          <div className="col-lg-4 col-12">
            <NewContactInfo />
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default ApplyNow
