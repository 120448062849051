import * as Yup from "yup"

// Custom method to validate phone numbers
Yup.addMethod(Yup.string, "phone", function (errorMessage) {
  return this.test("phone", errorMessage, function (value) {
    const { path, createError } = this

    // Validate based on custom logic
    if (value) {
      if (value.length === 12) {
        if (!value.startsWith("+") || !/^\+\d{11}$/.test(value)) {
          return createError({ path, message: errorMessage })
        }
      } else if (value.length === 10) {
        if (!/^\d{10}$/.test(value)) {
          return createError({ path, message: errorMessage })
        }
      } else {
        return createError({ path, message: errorMessage })
      }
    }

    // If no value or valid format, consider it valid to allow optional fields
    return true
  })
})

export const applyNowValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z ]{2,30}$/, "Invalid first name")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z ]{2,30}$/, "Invalid last name")
    .required("Please enter your last name"),
  smsfName: Yup.string().matches(/^[a-zA-Z ]{2,30}$/, "Invalid SMSF name"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter your email address"),
  phone: Yup.string()
    .phone("Invalid Phone Number")
    .required("Phone number is required"),
  postalCode: Yup.string().matches(/^[0-9]{4}$/, "Invalid Postcode"),
  estimatedLoanValue: Yup.string().test(
    "is-numerical-or-empty",
    "Estimated loan amount must be a whole number or empty",
    value => {
      // Allow the field to be optional: pass validation if it's empty
      if (!value) return true

      // Ensure value is a string, remove commas, and then check if it's a whole number
      const stringValue = value.toString().replace(/,/g, "")

      // Test if the string is a whole number
      return /^\d+$/.test(stringValue)
    }
  ),
})