import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import Success from "../Common/FormSuccess"
import Loader from "../Index/Loader"
import { applyNowValidationSchema } from "../../utils/FormValidationSchema"
import { formatNumberWithCommas } from "../../utils/FormValidations"

const NewContactForm = props => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timer
    if (submitSuccess) {
      timer = setTimeout(() => {
        setSubmitSuccess(false)
      }, 4000)
    }
    return () => clearTimeout(timer)
  }, [submitSuccess])

  const initialValues = {
    firstName: "",
    lastName: "",
    smsfName: "",
    email: "",
    phone: "",
    message: "",
    postalCode: "",
    loanType: "",
    propertyType: "",
    estimatedLoanValue: "",
  }

  const onSubmit = async (values, actions) => {
    setLoading(true)
    try {
      const formattedValues = {
        ...values,
        estimatedLoanValue: values.estimatedLoanValue.replace(/,/g, ""),
      }

      await axios.post(
        `${process.env.GATSBY_BACKEND_API_URL}/oxygen-loans/apply-now`,
        formattedValues
      )
      setSubmitSuccess(true)
      actions.resetForm()
      window.scrollTo({ top: 0, behavior: "smooth" })
    } catch (error) {
      console.error("Submission error:", error)
      setSubmitSuccess(false)
    } finally {
      setLoading(false)
      actions.setSubmitting(false)
    }
  }

  return (
    <section className="contact-area mt-3">
      {submitSuccess ? <Success /> : null}
      {loading ? <Loader /> : null}
      <div className="contact-form">
        <Formik
          initialValues={initialValues}
          validationSchema={applyNowValidationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, setFieldTouched, errors, touched, values }) => (
            <Form id="contactForm">
              <div className="row">

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      placeholder="Your first name"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      placeholder="Your last name"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="smsfName">SMSF Name</label>
                    <Field
                      type="text"
                      id="smsfName"
                      name="smsfName"
                      className="form-control"
                      placeholder="Your SMSF name"
                    />
                    <ErrorMessage
                      name="smsfName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="phone">Mobile Number</label>
                    <Field
                      type="tel"
                      id="phone"
                      name="phone"
                      className="form-control"
                      placeholder="Your mobile number"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="text"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="you@example.com"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="postalCode">Post Code</label>
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      className="form-control"
                      placeholder="Your post code"
                    />
                    <ErrorMessage
                      name="postalCode"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="loantype">Loan Type</label>
                        <div
                          className="btn-group"
                          role="group"
                          aria-labelledby="property-type-group"
                        >
                          <button
                            type="button"
                            className={`btn default-btn primary-btn ${
                              values.loanType === "Purchase" ? "active" : ""
                            }`}
                            onClick={() => {
                              setFieldValue("loanType", "Purchase")
                              setFieldTouched("loanType", true, false)
                            }}
                          >
                            Purchase
                          </button>
                          <button
                            type="button"
                            className={`btn default-btn primary-btn ${
                              values.loanType === "Refinance" ? "active" : ""
                            }`}
                            onClick={() => {
                              setFieldValue("loanType", "Refinance")
                              setFieldTouched("loanType", true, false)
                            }}
                          >
                            Refinance
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="propertyType">Property Type</label>
                        <div
                          className="btn-group"
                          role="group"
                          aria-labelledby="property-type-group"
                        >
                          <button
                            type="button"
                            className={`btn default-btn primary-btn ${
                              values.propertyType === "Residential"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setFieldValue("propertyType", "Residential")
                              setFieldTouched("propertyType", true, false)
                            }}
                          >
                            Residential
                          </button>
                          <button
                            type="button"
                            className={`btn default-btn primary-btn ${
                              values.propertyType === "Commercial"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setFieldValue("propertyType", "Commercial")
                              setFieldTouched("propertyType", true, false)
                            }}
                          >
                            Commercial
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="estimatedLoanValue">
                      Estimated Loan Amount
                    </label>
                    <Field
                      type="text"
                      name="estimatedLoanValue"
                      id="estimatedLoanValue"
                      className="form-control"
                      placeholder="Estimated Loan Amount"
                      value={formatNumberWithCommas(values.estimatedLoanValue)}
                      onChange={e => {
                        // Normalize and format the input for display
                        const formattedForDisplay = formatNumberWithCommas(
                          e.target.value
                        )
                        // Update the field's value for display purposes
                        setFieldValue("estimatedLoanValue", formattedForDisplay)
                      }}
                    />
                    <ErrorMessage
                      name="estimatedLoanValue"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <Field
                      as="textarea"
                      name="message"
                      id="message"
                      className="form-control"
                      placeholder="How can we help?"
                      rows="5"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <button
                    type="submit"
                    className="default-btn primary-btn disabled"
                  >
                    Send Message <span></span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default NewContactForm
